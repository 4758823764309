import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const start = () => {
  return (
    <div className="start-content">
      <h1 className="start-title">Welcome to the prototype of themex 🚀</h1>
      <p>
        Hello, my name is Karsten. As a front-end developer with over 15 years of experience in
        implementing websites, I have frequently experimented with new CSS frameworks. Frameworks
        like Bootstrap and Tailwind offer well-thought-out concepts that enable efficient and
        time-saving implementation of CSS declarations within the DOM. However, they haven't been
        efficient enough for me, especially in website development. Most websites today tend to
        follow a similar pattern and are not inherently complex. Typically, they involve fonts,
        colors, and other CSS properties that are defined globally and later passed on to child
        elements. The real challenge arises, especially when varying a web component, as it becomes
        confusing and difficult to replicate in other projects. The complexity further increases
        when a CMS is involved, leading to more intricate code in the DOM. This is why I created
        themex. It is primarily designed to ensure reproduction, clarity, and intuitiveness in all
        kinds of website projects. themex is built on{' '}
        <strong className="highlight">Vanilla JavaScript, CSS, and HTML.</strong>
      </p>

      <h2 className="start-subtitle">What is themex?</h2>
      <p>
        <strong className="highlight">themex is not another CSS framework!</strong> It's much more.
        themex aims to prevent a blank screen in your IDE and serves as a solid foundation for
        further development. It {''}
        <strong className="highlight">
          provides a DOM and CSS variables structure as well as CSS Declarations.
        </strong>
        Comprehensive documentation is not yet available, but I invite you to try it out and welcome
        your suggestions. You can define your theme in the browser and then download it, along with
        all the required assets, by clicking <strong className="highlight">'Generate Theme'</strong>
        . Experiment with it and consider if it could be a practical solution for your projects.
      </p>

      <p>What do you think? 🤔</p>
      <h2>How to use it?</h2>
      <p>
        <strong className="highlight">Step 1:</strong> Define your theme in the browser and download
        it, along with all the required assets, by clicking{' '}
        <strong className="highlight">'Generate Theme'</strong>. Only your CSS variables exist, and
        there is no overridden default value.{' '}
      </p>
      <p>
        <strong className="highlight">Step 2:</strong> Copy the downloaded files into your project
        folder. Or use NPM to install themex.{' '}
        <SyntaxHighlighter language="shell" style={agate}>
          npm i the-themex-project
        </SyntaxHighlighter>
      </p>
      <p>
        <strong className="highlight">Step 3:</strong> Link the CSS and JavaScript files.
      </p>
      <p>
        <strong className="highlight">Step 4:</strong> Add the HTML structure to your project.
      </p>
      <p>
        <strong className="highlight">Step 5:</strong>{' '}
        <a href="https://github.com/karstenbiedermann/themex-project">
          Support the project by giving it a star on GitHub.
        </a>{' '}
        ❤️
      </p>
      <h2>What's next?</h2>
      <p>Themex is still in the prototype phase.</p>
      <p>More features will be added soon:</p>
      <ul>
        <li>More components</li>
        <li>Switching between themes</li>
        <li>More documentation</li>
        <li>More examples</li>
      </ul>
    </div>
  );
};

export default start;
