import React, { useRef, useState, useEffect, useMemo } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const TypographyContent = ({ style }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');
  const [cssLoaded, setCssLoaded] = useState(false);
  const fontText = JSON.parse(localStorage.getItem('Typography')).fontText;

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = ['../dist/css/index.css', '../dist/css/icon.css', '../dist/css/text.css'];
    loadStyles(paths).then((cssText) => {
      setStyles(cssText);
      setCssLoaded(true);
    });
  }, []);

  const [cssStyle, setCssStyle] = useState('');
  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/themex.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  const iframeContent = useMemo(
    () => ({
      __html: `
  
    <style>
      ${styles}
      
    </style>
     <script>
    ${scripts}
  </script>
   <div class="text" style="${cssStyle}">
    <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-regular);">Weight regular</h1>
      </div>
      <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-medium);">Weight medium</h1>
      </div>
       <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-bold);">Weight bold</h1>
      </div>
       <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-extrabold);">Weight extrabold</h1>
      </div>
</div>
`,
    }),
    [styles, scripts, cssStyle],
  );

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent.__html);
      if (doc && doc.documentElement) {
        getThemeVariables(doc.documentElement);
      }
      doc.close();
      if (iframeContent) {
        setTimeout(() => {
          iframe.style.height = `${doc.documentElement.scrollHeight}px`;
        }, 200);
      }
    }
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = doc.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    doc.head.appendChild(link);
  }, [iframeContent, fontText, style]);

  return (
    <iframe
      title="componentIframe"
      ref={iframeRef}
      srcDoc={iframeContent.__html}
      style={{ opacity: cssLoaded ? 1 : 0 }}
    />
  );
};

const Typography = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Typography`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);

  const fontText = storedProps?.fontText || 'Nunito';
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Typography</div>
        <div className="ui-component-preview">
          <TypographyContent style={style} fontText={fontText} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<style>
:root {
  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;
  --weight-extrabold: 900;
}
</style>
`;

export default Typography;
