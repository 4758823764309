import React, { useRef, useState, useEffect, useMemo } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const CardContent = ({ style }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');
  const [cssLoaded, setCssLoaded] = useState(false);
  const fontText = JSON.parse(localStorage.getItem('Typography')).fontText;

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = ['../dist/css/index.css', '../dist/css/icon.css', '../dist/css/card.css'];
    loadStyles(paths).then((cssText) => {
      setStyles(cssText);
      setCssLoaded(true);
    });
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/themex.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  const iframeContent = useMemo(
    () => ({
      __html: `
    <style>
      ${styles}
    
    </style>
     <script>
    ${scripts}
  </script>
   <div class="card" style="${cssStyle}">
  <div class="card-inner">
  <div class="card-grid card--grid-3">
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>This is an eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>This is a sample text serving as a foundation for the card content. It complements the card title and forms the main part of the card's content. Replace this text with relevant information to bring your component to life.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>This is an eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>This is a sample text serving as a foundation for the card content. It complements the card title and forms the main part of the card's content. Replace this text with relevant information to bring your component to life.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>This is an eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>This is a sample text serving as a foundation for the card content. It complements the card title and forms the main part of the card's content. Replace this text with relevant information to bring your component to life.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
`,
    }),
    [styles, scripts, cssStyle],
  );

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent.__html);
      if (doc && doc.documentElement) {
        getThemeVariables(doc.documentElement);
      }
      doc.close();
      if (iframeContent) {
        setTimeout(() => {
          iframe.style.height = `${doc.documentElement.scrollHeight}px`;
        }, 290);
      }
    }
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = doc.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    doc.head.appendChild(link);
  }, [iframeContent, fontText, style]);

  return (
    <iframe
      title="componentIframe"
      ref={iframeRef}
      srcDoc={iframeContent.__html}
      style={{ opacity: cssLoaded ? 1 : 0 }}
    />
  );
};

const Cards = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Cards`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Card</div>
        <div className="ui-component-preview">
          <CardContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = ` <div class="card">
  <div class="card-inner">
    <div class="card-grid card--grid-3">
      <div class="card-item">
        <div class="card-icon">
          <i class="icon icon--md icon-page"></i>
        </div>
        <div class="card-text">
          <div class="card-eyebrow"><span>This is an eyebrow</span></div>
          <div class="card-headline">
            <h1>Card headline</h1>
          </div>
          <div class="card-text">
            <p>This is a sample text serving as a foundation for the card content.</p>
          </div>
          <div class="card-button">
            <a href="#" class="card-button-primary">Open link</a>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-icon">
          <i class="icon icon--md icon-page"></i>
        </div>
        <div class="card-text">
          <div class="card-eyebrow"><span>This is an eyebrow</span></div>
          <div class="card-headline">
            <h1>Card headline</h1>
          </div>
          <div class="card-text">
            <p>This is a sample text serving as a foundation for the card content.</p>
          </div>
          <div class="card-button">
            <a href="#" class="card-button-primary">Open link</a>
          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="card-icon">
          <i class="icon icon--md icon-page"></i>
        </div>
        <div class="card-text">
          <div class="card-eyebrow"><span>This is an eyebrow</span></div>
          <div class="card-headline">
            <h1>Card headline</h1>
          </div>
          <div class="card-text">
            <p>This is a sample text serving as a foundation for the card content.</p>
          </div>
          <div class="card-button">
            <a href="#" class="card-button-primary">Open link</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

export default Cards;
