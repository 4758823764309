import React, { useRef, useState, useEffect, useMemo } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const ButtonContent = ({ style }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');
  const [cssLoaded, setCssLoaded] = useState(false);
  const fontText = JSON.parse(localStorage.getItem('Typography')).fontText;

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = ['../dist/css/index.css', '../dist/css/icon.css', '../dist/css/button.css'];
    loadStyles(paths).then((cssText) => {
      setStyles(cssText);
      setCssLoaded(true);
    });
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/themex.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  const iframeContent = useMemo(
    () => ({
      __html: `
      <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
    <button class="button button--small button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--small button--secondary" style="${cssStyle}">
        Button secondary
      </button>
      <br />
      <br />
    <button class="button button--medium button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--medium button--secondary" style="${cssStyle}">
        Button secondary
      </button>
        <br />
      <br />
    <button class="button button--large button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--large button--secondary" style="${cssStyle}">
        Button secondary
      </button>
`,
    }),
    [styles, scripts, cssStyle],
  );

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent.__html);
      if (doc && doc.documentElement) {
        getThemeVariables(doc.documentElement);
      }
      doc.close();
      if (iframeContent) {
        setTimeout(() => {
          iframe.style.height = `${doc.documentElement.scrollHeight}px`;
        }, 200);
      }
    }
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = doc.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    doc.head.appendChild(link);
  }, [iframeContent, fontText, style]);

  return (
    <iframe
      title="componentIframe"
      ref={iframeRef}
      srcDoc={iframeContent.__html}
      style={{ opacity: cssLoaded ? 1 : 0 }}
    />
  );
};

const Button = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Button`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);

  let typographyProps;
  try {
    typographyProps = JSON.parse(localStorage.getItem(`Typography`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const fontText = typographyProps?.fontText || 'Roboto';
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Button</div>
        <div className="ui-component-preview">
          <ButtonContent style={style} fontText={fontText} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<button class="button button--small button--primary">
  Button primary
</button>

<button class="button button--medium button--secondary">
  Button medium secondary
</button>

<button class="button button--large button--primary">
  Button large primary
</button>

<button class="button button--large button--primary" disabled>
  Button disabled
</button>
`;

export default Button;
