import React, { useRef, useState, useEffect, useMemo } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const ModalContent = ({ style }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');
  const [cssLoaded, setCssLoaded] = useState(false);
  const fontText = JSON.parse(localStorage.getItem('Typography')).fontText;

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/index.css',
      '../dist/css/icon.css',
      '../dist/css/button.css',
      '../dist/css/modal.css',
    ];
    loadStyles(paths).then((cssText) => {
      setStyles(cssText);
      setCssLoaded(true);
    });
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/themex.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  const iframeContent = useMemo(
    () => ({
      __html: `
    <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
   <div style="${cssStyle}">
<div id="modal" class="modal modal--open" style="display: flex !important;">
  <div class="modal-body">
    <div class="modal-header">
      <div class="modal-title">Modal titel</div>
      <button class="modal-close">
        <!-- Close --></button>
    </div>
    <p>This is a sample text serving as a foundation for the modal content. It complements the modal title and forms the main part of the modal's content. Replace this text with relevant information to bring your component to life.</p>
  </div>
</div>
</div>
<div class="modal-backdrop" style="${cssStyle}"></div>
`,
    }),
    [styles, scripts, cssStyle],
  );

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent.__html);
      if (doc && doc.documentElement) {
        getThemeVariables(doc.documentElement);
      }
      doc.close();
      if (iframeContent) {
        setTimeout(() => {
          iframe.style.height = `400px`;
        }, 200);
      }
    }
  });
  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = doc.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    doc.head.appendChild(link);
  }, [iframeContent, fontText, style]);

  return (
    <iframe
      title="componentIframe"
      ref={iframeRef}
      srcDoc={iframeContent.__html}
      style={{ opacity: cssLoaded ? 1 : 0 }}
    />
  );
};

const Modal = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Modal`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Modal</div>
        <div className="ui-component-preview">
          <ModalContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<button class="modal-trigger" data-target="modal">Open modal</button>
<div id="modal" class="modal">
  <div class="modal-body">
    <div class="modal-header">
      <div class="modal-title">Modal titel</div>
      <button class="modal-close"><!-- Close --></button>
    </div>
    <p>This is a sample text serving as a foundation for the modal content.</p>
  </div>
</div>
`;

export default Modal;
