import React, { useRef, useState, useEffect, useMemo } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const AccordionContent = ({ style }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');
  const [cssLoaded, setCssLoaded] = useState(false);
  const fontText = JSON.parse(localStorage.getItem('Typography')).fontText;

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = ['../dist/css/index.css', '../dist/css/icon.css', '../dist/css/accordion.css'];
    loadStyles(paths).then((cssText) => {
      setStyles(cssText);
      setCssLoaded(true);
    });
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/themex.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  const iframeContent = useMemo(
    () => ({
      __html: `
    <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
    <div
  class="accordion" style="${cssStyle}"
>
  <div class="accordion-inner">
    <div class="accordion-item visible">
      <div class="accordion-header">
          <div class="accordion-title">Accordion title</div>
      </div>
      <div
        class="accordion-body"
      >
        <div class="accordion-body-inner">
          This is a sample text serving as a foundation for the accordion content. It complements the accordion title and forms the main part of the accordion's content. Replace this text with relevant information to bring your component to life.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div class="accordion-header">
          <div class="accordion-title">
            Accordion title
          </div>
      </div>
      <div class="accordion-body">
        <div class="accordion-body-inner">
         This is a sample text serving as a foundation for the accordion content. It complements the accordion title and forms the main part of the accordion's content. Replace this text with relevant information to bring your component to life.
        </div>
      </div>
    </div>
  </div>
</div>
   
`,
    }),
    [styles, scripts, cssStyle],
  );

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent.__html);
      if (doc && doc.documentElement) {
        getThemeVariables(doc.documentElement);
      }
      doc.close();
      getThemeVariables(doc.documentElement);
      if (iframeContent) {
        setTimeout(() => {
          iframe.style.height = `${doc.documentElement.scrollHeight}px`;
        }, 200);
      }
    }
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = doc.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    doc.head.appendChild(link);
  }, [iframeContent, fontText, style]);

  return (
    <iframe
      title="componentIframe"
      ref={iframeRef}
      srcDoc={iframeContent.__html}
      style={{ opacity: cssLoaded ? 1 : 0 }}
    />
  );
};

const Accordion = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Accordion`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }

  const style = convertPropsToStyle(storedProps || props);

  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Accordion</div>
        <div className="ui-component-preview">
          <AccordionContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = ` <div class="accordion">
  <div class="accordion-inner">
    <div class="accordion-item visible">
      <div class="accordion-header">
        <div class="accordion-title">Accordion title</div>
      </div>
      <div class="accordion-body">
        <div class="accordion-body-inner">
          This is a sample text serving as a foundation for the accordion content.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div class="accordion-header">
        <div class="accordion-title">
          Accordion title
        </div>
      </div>
      <div class="accordion-body">
        <div class="accordion-body-inner">
          This is a sample text serving as a foundation for the accordion content.
        </div>
      </div>
    </div>
  </div>
</div>
`;

export default Accordion;
